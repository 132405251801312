var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('dashboard-box',{staticClass:"px-4",attrs:{"back":true,"back-component-name":_vm.parentRouteName || null},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("companies.create_company")))])]},proxy:true},{key:"preview",fn:function(){return [_c('div',{staticClass:"row px-4 py-4"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('text-input',{attrs:{"is-inline":true,"helper-text":_vm.$t('companies.please_enter_company_name'),"placeholder":_vm.$t('companies.please_enter_company_name'),"title":_vm.$t('companies.company_name'),"required":true,"model":_vm.company.name,"is-valid":_vm.company.name !== null && _vm.company.name.length !== 0},on:{"update:model":function($event){return _vm.$set(_vm.company, "name", $event)}}}),_c('textarea-input',{attrs:{"is-inline":true,"helper-text":_vm.$t('companies.please_enter_company_address'),"placeholder":_vm.$t('companies.please_enter_company_address'),"required":false,"model":_vm.company.address,"title":_vm.$t('companies.company_address'),"is-valid":_vm.company.address != null && _vm.company.address.length !== 0
                    ? true
                    : null},on:{"update:model":function($event){return _vm.$set(_vm.company, "address", $event)}}}),_c('text-input',{attrs:{"is-inline":true,"helper-text":_vm.$t('companies.please_enter_tax_administration'),"placeholder":_vm.$t('companies.please_enter_tax_administration'),"title":_vm.$t('companies.tax_administration'),"required":false,"model":_vm.company.tax_administration,"is-valid":_vm.company.tax_administration != null &&
                    _vm.company.tax_administration.length !== 0
                      ? true
                      : null},on:{"update:model":function($event){return _vm.$set(_vm.company, "tax_administration", $event)}}}),_c('text-input',{attrs:{"is-inline":true,"helper-text":_vm.$t('companies.please_enter_tax_number'),"placeholder":_vm.$t('companies.please_enter_tax_number'),"title":_vm.$t('companies.tax_number'),"required":false,"model":_vm.company.tax_number,"is-valid":_vm.company.tax_number != null &&
                    _vm.company.tax_number.length !== 0
                      ? true
                      : null},on:{"update:model":function($event){return _vm.$set(_vm.company, "tax_number", $event)}}}),_c('text-input',{attrs:{"is-inline":true,"helper-text":_vm.$t('companies.please_enter_email'),"placeholder":_vm.$t('companies.please_enter_email'),"title":_vm.$t('companies.email'),"required":false,"model":_vm.company.email,"is-valid":_vm.company.email != null &&
                    _vm.company.email.length !== 0
                      ? true
                      : null},on:{"update:model":function($event){return _vm.$set(_vm.company, "email", $event)}}})],1)])]),_c('div',{staticClass:"col-4 text-center"},[(_vm.company.avatar !== null)?_c('img',{class:{
              'w-100px h-100px rounded-lg mb-5': _vm.company.avatar !== null,
            },attrs:{"src":_vm.avatar_file,"alt":""}}):_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.getIconByKey('icons.meeting.company-logo', {
                class: 'w-75px h-75px d-inline-block',
              })
            )}}),_c('file-input',{attrs:{"model":_vm.company.avatar,"is-inline":false,"title":_vm.$t('companies.company_profile_image'),"helper-text":_vm.$t('companies.please_select_company_profile_image'),"required":true,"error":_vm.company.avatar == null && _vm.avatar_file != null
                  ? _vm.$t('companies.company_profile_image_cant_be_empty')
                  : null},on:{"fileChange":_vm.onProfileImageChange,"update:model":function($event){return _vm.$set(_vm.company, "avatar", $event)}}})],1)]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-sm btn-outline-danger mr-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("general.back"))+" ")]),_c('button',{staticClass:"btn btn-sm btn-success",attrs:{"type":"button","disabled":!_vm.isFormValid},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])])]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }